import { gql } from "@apollo/client";
import { StudentMeetingDetails_StudentNextSessionFragment } from "@generated/graphql";
import {
  floatingToZonedDateTime,
  getDateInfo,
  localizedTime,
} from "@utils/dateTime";
import { ButtonLink, SubjectBadge } from "components/shared";
import add from "date-fns/add";
import { DetailsRow } from "types/global";

getStudentMeetingDetails.fragments = {
  studentNextSession: gql`
    fragment StudentMeetingDetails_StudentNextSession on StudentNextSession {
      id
      cohortId
      timeZone
      cohortName
      meetingRoom
      cohortSubject
      durationMinutes
      cohortSubSubject
      startFloatingDateTime
    }
  `,
};

export function getStudentMeetingDetails(
  studentNextSession: StudentMeetingDetails_StudentNextSessionFragment
): DetailsRow[] {
  const {
    timeZone,
    meetingRoom,
    cohortSubject,
    durationMinutes,
    startFloatingDateTime,
  } = studentNextSession;

  const startDateTime = startFloatingDateTime
    ? floatingToZonedDateTime(new Date(startFloatingDateTime), timeZone)
    : new Date();

  const endDateTime = add(startDateTime, {
    minutes: durationMinutes,
  });

  const startTime = localizedTime(getDateInfo(startDateTime).time, false);
  const endTime = localizedTime(getDateInfo(endDateTime).time, false);

  return [
    [
      "Session Subject",
      <span key={0}>
        {cohortSubject ? (
          <SubjectBadge subject={cohortSubject} />
        ) : (
          "Unknown Subject"
        )}
      </span>,
    ],
    [
      "Session Date",
      <span key={0}>
        <span className="mr-1">
          {new Intl.DateTimeFormat([], { weekday: "short" }).format(
            startDateTime
          )}
        </span>
        <span>{startDateTime.toLocaleDateString()}</span>
      </span>,
    ],
    [
      "Session Time",
      <span key={0}>
        {startTime} - {endTime}{" "}
      </span>,
    ],
    [
      "Meeting Link",
      meetingRoom ? (
        <ButtonLink height="xs" href={meetingRoom}>
          Join Meeting
        </ButtonLink>
      ) : (
        "Meeting link has not been created yet."
      ),
    ],
  ];
}
